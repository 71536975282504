import { getMediaFromURL } from "./getMediaFromURL";

export function sendData(name, email, phone) {
    var midia = getMediaFromURL();

    fetch('https://mozak.hypnobox.com.br/email.receber.php', {
      method: 'POST',
      body: JSON.stringify({
        nome: name,
        email: email,
        tel_celular: phone,
        id_produto: 35,
        midia: midia
      })
    })
      .then(response => response.json())
      .then(data => {
        alert('Dados enviados com sucesso!');
        window.location.reload();
      })
  }